import { trpc } from '@api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

export const useGetOffersRecommendations = () => {
  const { selectedCompany } = useSelectedCompany();
  const { data, isLoading } = trpc.getOffersRecommendations.useQuery({
    companyId: selectedCompany.id,
  });

  return { offersRecommendations: data, isLoading };
};
