import {
  ContentContainer,
  Date,
  DateContainer,
  IconContainer,
  MainContainer,
  Space,
  Text,
  Title,
  ButtonContainer,
  Button,
} from './styles';
import { InboxMessageWithGroup } from '../../types';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../Icon';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { useAckInboxMessage } from '@/hooks/use-ack-inbox-message';
import { useEffect, useState } from 'react';

const InboxMessage = ({
  inboxMessage: {
    id,
    title,
    description,
    createdAt,
    type,
    acknowledged,
    timeGroup,
    redirectPath,
  },
  opt: { invertTitle },
}: {
  inboxMessage: InboxMessageWithGroup;
  opt: {
    invertTitle: boolean;
  };
}) => {
  const [inboxMessageAcknowledged, setInboxMessageAcknowledged] =
    useState<boolean>(acknowledged);

  const navigate = useNavigate();
  let dateToDisplay: string = '';
  const { mutate } = useAckInboxMessage();

  switch (timeGroup) {
    case 'today':
      dateToDisplay = 'Hoje';
      break;
    case 'yesterday':
      dateToDisplay = 'Ontem';
      break;
    default:
      dateToDisplay = dayjs(createdAt).locale('pt-br').format('D [de] MMM');
      break;
  }

  const InboxTitle = <Title variant="body3">{title}</Title>;
  const InboxDate = (
    <>
      <DateContainer>
        <Date variant="caption">{dateToDisplay}</Date>
        <Space />
        <Date variant="caption">{dayjs(createdAt).format('HH:mm')}</Date>
      </DateContainer>
    </>
  );

  const onClick = () => {
    mutate({ id });
    setInboxMessageAcknowledged(true);
  };

  const handleButtonClick = (redirectPath: string) => {
    if (redirectPath.startsWith('https://')) {
      window.open(redirectPath, '_blank');
    } else {
      navigate(redirectPath);
    }
  };

  useEffect(() => {
    setInboxMessageAcknowledged(acknowledged);
  }, [acknowledged]);

  return (
    <MainContainer
      acknowledged={inboxMessageAcknowledged}
      onClick={() => onClick()}
    >
      <IconContainer>
        <Icon type={type} acknowledged={inboxMessageAcknowledged} />
      </IconContainer>
      <ContentContainer>
        {invertTitle ? (
          <>
            {InboxDate}
            {InboxTitle}
          </>
        ) : (
          <>
            {InboxTitle}
            {InboxDate}
          </>
        )}
        <Text variant="caption">{description}</Text>
        {redirectPath ? (
          <ButtonContainer onClick={() => handleButtonClick(redirectPath)}>
            <Button variant="caption">Saiba mais</Button>
            <Icons
              name="IconArrowRight"
              size={15}
              color="var(--color-secondary-dark5)"
            />
          </ButtonContainer>
        ) : null}
      </ContentContainer>
    </MainContainer>
  );
};

export { InboxMessage };
