import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledTasksListLabelWrapper = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 9;
  padding: 8px 0px;
  background-color: ${({ bgColor }) => bgColor};

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.neutral[80]};
    left: 0;
  }
`;

export const StyledTasksListLabel = styled(Typography)<{ bgColor: string }>`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400;
  text-align: center;
  position: relative;
  padding: 0px 16px;
  background-color: ${({ bgColor }) => bgColor};
`;

export const StyledWidgetWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  height: 534px;
`;

export const StyledTypeIcon = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.secondary[50]};
`;

export const StyledListLabel = styled.div`
  padding: 0px 24px;
`;

export const StyledTabContent = styled.div<{ bgColor: string }>`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 24px 0px;
  border-bottom-left-radius: ${({ theme }) => theme.borders.radius.s};
  border-bottom-right-radius: ${({ theme }) => theme.borders.radius.s};
  background-color: ${({ bgColor }) => bgColor};
`;
