import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import { ErrorBoundary } from '@utils';
import { trpc, trpcClient, queryClient } from './api/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { TaskHeaderIcon } from './pages/AdminHome/shared/TaskHeaderIcon';
import { BrowserRouter } from 'react-router-dom';
import {
  GroupedByTime,
  InboxMessageWithGroup,
} from './pages/AdminHome/components/QuickAccessSection/components/InboxWidget/types';
import { InboxMessageList } from './pages/AdminHome/components/QuickAccessSection/components/InboxWidget/components/InboxMessageList';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundaryClass: ErrorBoundary,
});

export const { bootstrap, mount, unmount } = lifecycles;

function TaskHeaderNotificationIcon() {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <TaskHeaderIcon />
      </QueryClientProvider>
    </trpc.Provider>
  );
}

function InboxHeaderList({
  formattedInboxMessages,
  setFormattedInboxMessages,
  inboxResponseMetadata,
  query,
  opt,
}: {
  formattedInboxMessages: Record<GroupedByTime, InboxMessageWithGroup[]>;
  setFormattedInboxMessages: Dispatch<
    SetStateAction<Record<GroupedByTime, InboxMessageWithGroup[]>>
  >;
  inboxResponseMetadata: MutableRefObject<{
    isLoading: boolean;
    metadata?: {
      totalCount: number;
      totalPages: number;
      currentPage: number;
      nextPage: number | null;
      prevPage: number | null;
    };
  }>;
  query: {
    page: number;
    limit: number;
  };
  opt: {
    invertTitle: boolean;
    uniqueList: boolean;
    showAckAll: boolean;
  };
}) {
  return (
    <BrowserRouter>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <InboxMessageList
            formattedInboxMessages={formattedInboxMessages}
            setFormattedInboxMessages={setFormattedInboxMessages}
            inboxResponseMetadata={inboxResponseMetadata}
            query={query}
            opt={opt}
          />
        </QueryClientProvider>
      </trpc.Provider>
    </BrowserRouter>
  );
}

export { TaskHeaderNotificationIcon, InboxHeaderList };
