import { Tabs } from '@/components/Tabs';
import { UIEventHandler, useEffect, useRef, useState } from 'react';
import { InboxMessageList } from '../InboxMessageList';
import { TabTitle } from '@/components/Tabs/components/TabTitle';
import { GroupedByTime, InboxMessageWithGroup } from '../../types';
import { StyledInboxMessageList, StyledTabContent } from './styles';

type InboxContentProps = {
  bgColor: string;
};

export const InboxContent = ({ bgColor }: InboxContentProps) => {
  const inboxResponseMetadata = useRef<{
    isLoading: boolean;
    metadata?: {
      totalCount: number;
      totalPages: number;
      currentPage: number;
      nextPage: number | null;
      prevPage: number | null;
    };
  }>({ isLoading: false });

  const [formattedInboxMessages, setFormattedInboxMessages] = useState<
    Record<GroupedByTime, InboxMessageWithGroup[]>
  >({
    today: [],
    yesterday: [],
    others: [],
  });

  const [inboxQuery, setQuery] = useState<{
    page: number;
    limit: number;
  }>({
    page: 1,
    limit: 10,
  });

  const [fetchInboxMessages, setFetchInboxMessages] = useState<boolean>(false);

  useEffect(() => {
    if (
      fetchInboxMessages &&
      !inboxResponseMetadata.current.isLoading &&
      inboxResponseMetadata.current.metadata?.nextPage
    ) {
      const nextPage = inboxResponseMetadata.current.metadata.nextPage;
      setQuery((oldValue) => ({
        page: nextPage,
        limit: oldValue.limit,
      }));
    }
    setFetchInboxMessages(false);
  }, [fetchInboxMessages]);

  const onScroll: UIEventHandler<HTMLDivElement> = (event) => {
    const element = event.target as HTMLDivElement;
    const isBotton =
      Math.abs(
        element.scrollHeight - (element.scrollTop + element.clientHeight),
      ) <= 200;
    if (isBotton) {
      setFetchInboxMessages(true);
    }
  };

  return (
    <Tabs
      tabs={[
        {
          title: (
            <TabTitle
              text="Notificações da Flash"
              value={inboxResponseMetadata.current.metadata?.totalCount || 0}
            />
          ),
          content: (
            <StyledTabContent bgColor={bgColor}>
              <StyledInboxMessageList
                inboxListStyle={{
                  spacing_x: 0,
                  spacing_y: 16,
                  gap: 8,
                  padding_right: 24,
                  padding_left: 24.5,
                }}
                onScroll={onScroll}
              >
                <InboxMessageList
                  formattedInboxMessages={formattedInboxMessages}
                  setFormattedInboxMessages={setFormattedInboxMessages}
                  inboxResponseMetadata={inboxResponseMetadata}
                  query={inboxQuery}
                  opt={{
                    invertTitle: true,
                    uniqueList: true,
                    showAckAll: false,
                  }}
                />
              </StyledInboxMessageList>
            </StyledTabContent>
          ),
        },
        {
          title: <TabTitle text="Notificações da Flash" value={2} />,
          content: (
            <StyledTabContent bgColor={bgColor}>
              <div></div>
            </StyledTabContent>
          ),
        },
      ]}
    />
  );
};
