import { useFlag } from '@flash-tecnologia/feature-flags';
import { IconTypes, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useNavigate } from 'react-router-dom';
import { useGetOffersRecommendations } from '../../hooks/use-get-offers-recommendations';
import { PageContainer, SectionBlocks } from './recommendations-section.styles';
import { HighlightCard } from '@/components';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { Product } from '../Product';
import { setEventTracking } from '@/utils';

export const RecommendationsSection = () => {
  const [t] = useTranslation('translations', { keyPrefix: 'adminHomePage' });
  const navigate = useNavigate();
  const isRecommendationsSectionEnabled = useFlag({
    flagName: 'FLASH_OS_HOME_RECOMMENDATIONS_SECTION',
  });
  const { offersRecommendations, isLoading: isLoadingRecommendations } =
    useGetOffersRecommendations();

  const onPeopleProductClick = () => {
    setEventTracking('home_people_product_know_more_clicked');
    navigate('/flows/hiring');
  };

  const onRecommendationsClick = (marketPageId: string) => {
    setEventTracking('home_recommendations_know_more_clicked', {
      market_page_id: marketPageId,
    });
    navigate(`/acquisition/overview?id=${marketPageId}`);
  };

  if (isLoadingRecommendations) {
    return (
      <PageContainer>
        <Skeleton variant="rounded" height={320} />
      </PageContainer>
    );
  }

  if (!isRecommendationsSectionEnabled) {
    return (
      <PageContainer>
        <Typography variant="headline7" style={{ marginBottom: '20px' }}>
          {t('productsSectionTitle')}
        </Typography>
        <SectionBlocks>
          <Product
            icon="IconUsers"
            title={t('peopleProduct.title')}
            text={t('peopleProduct.description')}
            topics={[
              ['IconUserPlus', t('peopleProduct.admissionTopic')],
              ['IconMessageDots', t('peopleProduct.trainingTopic')],
              ['IconRocket', t('peopleProduct.performanceTopic')],
            ]}
            actionText={t('peopleProduct.actionText')}
            onClick={onPeopleProductClick}
          />
        </SectionBlocks>
      </PageContainer>
    );
  }

  if (!offersRecommendations?.length) {
    return null;
  }

  return (
    <PageContainer>
      <Typography variant="headline7" style={{ marginBottom: '20px' }}>
        {t('recommendationsSectionTitle')}
      </Typography>
      <SectionBlocks>
        {offersRecommendations.map((offer) => (
          <HighlightCard
            imageUrl={offer.imageUrl}
            title={offer.title}
            text={offer.description}
            actionText={offer.ctaText}
            topics={offer.topics.map(({ icon, text }) => ({
              icon: icon as IconTypes,
              text,
            }))}
            onClick={() => onRecommendationsClick(offer.marketPageId)}
          />
        ))}
      </SectionBlocks>
    </PageContainer>
  );
};
