import {
  getAuthenticatedUser,
  getFromLS,
  setInLS,
  useLegacyPermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import { useFlag, WithFlag } from '@flash-tecnologia/feature-flags';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  MainPage,
  OnboardingTasksContainer,
  PageHeader,
  RowPageContainer,
  PageContainer,
  RowPageContainerWidgets,
} from './admin-home.styles';
import { useEffect, useMemo, useState } from 'react';
import { PermissionsBanner } from './components/PermissionsBanner';
import { OnboardingSteps } from './components/OnboardingSteps';
import {
  FLASH_OS_HOME_PROMOTIONAL_BANNER,
  PromotionalBanner,
} from './components/PromotionalBanner';
import { OnboardingProvider } from '@context/onboarding';
import { QuickAccessSection } from './components/QuickAccessSection';
import { ContractModal, EmailConfirmModal, OnboardingModal } from '@components';
import { usePageTracking } from '@/hooks';
import AlertSection from '@/components/AlertSection';
import { useIsAllowedToAccessAdminHome } from './hooks/is-allowed-to-access-admin-home';
import { TaskWidget } from './components/QuickAccessSection/components/TaskWidget';
import { InboxWidget } from './components/QuickAccessSection/components/InboxWidget';
import { RecommendationsSection } from './components/RecommendationsSection';

export const AdminHome = () => {
  const [t] = useTranslation('translations', { keyPrefix: 'adminHomePage' });
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();
  const isAllowedToAccess = useIsAllowedToAccessAdminHome();

  const [searchParams, setSearchParams] = useSearchParams();
  const showOnboardingParam = searchParams.get('onboarding');
  const isPromotionalBannerEnabled = useFlag({
    flagName: FLASH_OS_HOME_PROMOTIONAL_BANNER,
  });

  const [username, setUsername] = useState('');

  const { checkSectionPermission, permissions } = useLegacyPermissions();
  const hasBenefits = useMemo(() => {
    return checkSectionPermission('flash_benefits');
  }, [permissions]);

  const userOnboardingSteps = getFromLS('userOnboardingSteps');

  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const [isOnboardingStepsOpen, setIsOnboardingStepsOpen] = useState(
    userOnboardingSteps?.isFixedOnHome ?? true,
  );

  usePageTracking('home_screen', {
    view: 'admin',
    companyId: selectedCompany?.id,
    companyName: selectedCompany?.name,
  });

  useEffect(() => {
    if (!isAllowedToAccess) {
      navigate('/home/employee');
    }

    const getUsername = async () => {
      const user = await getAuthenticatedUser();
      setUsername(user?.name ?? '');
    };

    getUsername();

    const pinOnboardingHandler = () => {
      setIsOnboardingStepsOpen(true);
      setInLS({
        key: 'userOnboardingSteps',
        value: { ...userOnboardingSteps, isFixedOnHome: true },
      });
    };

    window.addEventListener('pinOnboardingStepsOnHome', pinOnboardingHandler);

    return () => {
      window.removeEventListener(
        'pinOnboardingStepsOnHome',
        pinOnboardingHandler,
      );
    };
  }, []);

  useEffect(() => {
    if (showOnboardingParam === 'true') {
      setOnboardingModalOpen(true);
      searchParams.delete('onboarding');
      setSearchParams(searchParams);
    }
  }, [showOnboardingParam]);

  const onOnboardingClose = () => {
    setIsOnboardingStepsOpen(false);
    setInLS({
      key: 'userOnboardingSteps',
      value: { ...userOnboardingSteps, isFixedOnHome: false },
    });
  };

  const showBannerOrSetupSection =
    isPromotionalBannerEnabled || (isOnboardingStepsOpen && hasBenefits);

  return (
    <OnboardingProvider>
      <>
        <AlertSection />
        <MainPage>
          <PageHeader
            title={`${t('welcome')}${username ? `, ${username}!` : '!'}`}
          />
          <PermissionsBanner />
          {showBannerOrSetupSection && (
            <RowPageContainer>
              {isPromotionalBannerEnabled && <PromotionalBanner />}
              {isOnboardingStepsOpen && hasBenefits && (
                <OnboardingTasksContainer>
                  <OnboardingSteps onClose={onOnboardingClose} />
                </OnboardingTasksContainer>
              )}
            </RowPageContainer>
          )}
          {/* Temporary while quick access for others BUs doesn't exist among its permissions */}
          {hasBenefits && (
            <PageContainer>
              <QuickAccessSection />
            </PageContainer>
          )}
          <RowPageContainerWidgets>
            <TaskWidget />
            <WithFlag flagName="FLASH_OS_INBOX">
              <InboxWidget />
            </WithFlag>
          </RowPageContainerWidgets>
          <RecommendationsSection />
        </MainPage>
        <EmailConfirmModal />
        <OnboardingModal
          open={onboardingModalOpen}
          username={username}
          onClose={() => setOnboardingModalOpen(false)}
        />
        <ContractModal />
      </>
    </OnboardingProvider>
  );
};
