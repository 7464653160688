import { Widget } from '@/components/Widget';
import { StyledWidgetWrapper } from './styles';
import { InboxContent } from './components/InboxContent';
import { useTheme } from 'styled-components';

export const InboxWidget = () => {
  const theme = useTheme();

  return (
    <StyledWidgetWrapper>
      <Widget title="Caixa de entrada" icon="IconMail">
        <InboxContent bgColor={theme.colors.neutral[95]} />
      </Widget>
    </StyledWidgetWrapper>
  );
};
